import { defineNuxtPlugin } from '#app'

function getScript() {
  return `
 var _mtm = window._mtm = window._mtm || [];
_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.async=true; g.src='https://publica.opsone-analytics.ch/js/container_RjgfA0rB.js'; s.parentNode.insertBefore(g,s);
`
}

export default defineNuxtPlugin({
  name: 'publica-analytics',
  setup(nuxtApp) {
    // Don't include the script stuff while developing.
    if (!import.meta.dev) {
      useHead({
        script: [
          {
            key: 'analytics-load',
            innerHTML: getScript(),
            tagPriority: 9003,
          },
        ],
      })
    }

    // Where the dataLayer stuff is stored during SSR. We attach it to the
    // head after the page was rendered.
    const ssrDataLayer = useState<any[]>('ssrDataLayer', () => [])

    if (import.meta.server) {
      nuxtApp.hook('app:rendered', (ctx) => {
        ctx.ssrContext?.head.push({
          script: [
            {
              children: `window._mtm = ${JSON.stringify(ssrDataLayer.value)}`,
              tagPriority: -100,
            },
          ],
        })
        ssrDataLayer.value = []
      })
    }

    const router = useRouter()
    const { trackSwitchPage } = useAnalytics()
    const ssrTrackedUrl = useState('ssr_tracked_url', () => '')

    if (import.meta.client) {
      let timeout: null | number = null
      let lastSet = ''

      router.afterEach((to, from) => {
        if (ssrTrackedUrl.value === to.fullPath) {
          return
        }
        // Prevent tracking initial page load.
        if (to.fullPath === from.fullPath) {
          return
        }
        ssrTrackedUrl.value = ''
        if (timeout) {
          window.clearTimeout(timeout)
        }
        timeout = window.setTimeout(() => {
          if (lastSet !== to.fullPath) {
            trackSwitchPage(to.fullPath, document.title)
            lastSet = to.fullPath
          }
        }, 1000)
      })
    }
  },
})
